/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Peter's Portfolio",
  description:
    "Software Engineer, Graphic Designer, and Customer Experience (CX) Specialist crafting robust web applications with modern technologies. Building scalable solutions that transform user experiences and drive technical innovation.",
  og: {
    title: "Peter's Portfolio",
    type: "website",
    url: "http://peter-kibet.com/",
  },
};

//Home Page
const greeting = {
  title: "Peter Kibet",
  logo_name: "Spidey",
  nickname: "{ Designer + Coder }",
  subTitle:
    "Software Engineer and Customer Experience (CX) Specialist with expertise in Graphic Design.",
  message:
    "Committed to bridging the gap between innovative technology and user-centric design, creating solutions that make a tangible impact in both digital and culinary spaces.",
  resumeLink:
    "https://drive.google.com/file/d/14Vph7lbleE0jvQCSwUKjBgS6SMV_dGR9/view?usp=sharing",
  portfolio_repository: "https://www.spideyinc.com/",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/Spidey-Acer",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/spidey/"

  {
    name: "Github",
    link: "https://github.com/Spidey-Acer",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  // {
  //   name: "Behance",
  //   link: "https://www.behance.net/peterkibet2",
  //   fontAwesomeIcon: "fa-behance", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/Peter-Kibet/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "Gmail",
    link: "mailto:peterkibet@spideyinc.com",
    fontAwesomeIcon: "fa fa-envelope", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://x.com/Peter_Montana_J",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/spideyincorporation/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  {
    name: "Instagram",
    link: "https://www.instagram.com/spidey_inc/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Software Engineering & Architecture",
      fileName: "EngineeringImg",
      skills: [
        "🕷️ Architecting scalable applications using React, Next.js, and TypeScript with modern design patterns",
        "🕷️ Implementing microservices architecture with Node.js, Express, and Python Flask",
        "🕷️ Developing cross-platform mobile solutions using Flutter and React Native frameworks",
      ],
      softwareSkills: [
        {
          skillName: "TypeScript",
          fontAwesomeClassname: "simple-icons:typescript",
          style: {
            color: "#3178C6",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "Next.js",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "devicon-plain:nodejs-wordmark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
      ],
    },
    {
      title: "Cloud Architecture & DevOps Engineering",
      fileName: "CloudInfraImg",
      skills: [
        "🕷️ Designing and implementing cloud-native solutions on GCP, AWS, and Azure",
        "🕷️ Orchestrating containerized applications with Docker and Kubernetes",
        "🕷️ Engineering distributed database systems using PostgreSQL, MongoDB, and Firebase",
      ],
      softwareSkills: [
        {
          skillName: "Google Cloud Platform",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "Amazon Web Services",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#2496ED",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
      ],
    },
    {
      title: "Software Design & User Experience",
      fileName: "DesignImg",
      skills: [
        "🕷️ Engineering user-centric interfaces with advanced animation and interaction patterns",
        "🕷️ Implementing design systems and component libraries for enterprise applications",
        "🕷️ Developing high-fidelity prototypes and technical specifications using industry-standard tools",
      ],
      softwareSkills: [
        {
          skillName: "Adobe Creative Suite",
          fontAwesomeClassname: "simple-icons:adobe",
          style: {
            color: "#FF0000",
          },
        },
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Material Design",
          fontAwesomeClassname: "simple-icons:materialdesign",
          style: {
            color: "#757575",
          },
        },
        {
          skillName: "Tailwind CSS",
          fontAwesomeClassname: "simple-icons:tailwindcss",
          style: {
            color: "#06B6D4",
          },
        },
        {
          skillName: "Framer Motion",
          fontAwesomeClassname: "simple-icons:framer",
          style: {
            color: "#0055FF",
          },
        },
      ],
    },
  ],
};

export default skills;

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/Peter-Kibet/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/peter_kibet",
    },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/spidey",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    {
      siteName: "Kaggle",
      iconifyClassname: "simple-icons:kaggle",
      style: {
        color: "#20BEFF",
      },
      profileLink: "https://www.kaggle.com/peterkibetspidey",
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Technical University of Mombasa",
      subtitle: "Bachelor of Science in Information Technology",
      logo_path: "TUM.png",
      alt_name: "TUM",
      duration: "2019 - 2024",
      descriptions: [
        "🕷️ Specialized in Software Engineering with focus on System Architecture, Data Structures & Algorithms, Cloud Computing, and Enterprise Systems",
        "🕷️ Architected and developed an AI-integrated Learning Management System (LMS) for university short courses using Next.js, Python and TensorFlow",
        "🕷️ Mastered modern software engineering practices including cloud-native development, DevOps automation, and microservices architecture",
      ],
      website_link: "http://tum.ac.ke",
    },
    {
      title: "Microverse",
      subtitle: "Software Engineering Program",
      logo_path: "microverse small.png",
      alt_name: "Microverse",
      duration: "Apr 2023 - Mar 2024",
      descriptions: [
        "🕷️ Engaged in intensive remote pair programming and collaborated with developers across 6 continents on real-world projects",
        "🕷️ Built production-ready applications using React, Ruby on Rails, and PostgreSQL with comprehensive CI/CD pipelines",
        "🕷️ Completed 1000+ hours of hands-on coding, technical mentorship, agile development, and project management",
      ],
      website_link: "https://www.microverse.org",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Early Career Internship Program",
      subtitle: "- EABL(DIAGEO)",
      logo_path: "EABL.png",
      certificate_link:
        "https://drive.google.com/file/d/1NuquQSLnI1S-U-mPvqWrKDDCB6lOBm0q/view?usp=drive_link",
      alt_name: "EABL",
      color_code: "#ffffff",
    },
    {
      title: "Full Stack Web Development",
      subtitle: "- Microverse",
      logo_path: "microverse small.png",
      certificate_link:
        "https://drive.google.com/file/d/1Id_9hAB_JdPJ8mPAUFPLZB93_TTydMCH/view?usp=sharing",
      color_code: "#6f36ff",
    },
    {
      title: "AI Career Essentials",
      subtitle: "- ALX Africa",
      logo_path: "ALX.svg",
      certificate_link: "https://intranet.alxswe.com/certificates/shY9PRzFEL",
      alt_name: "ALX Africa",
      color_code: "#00acd4",
    },
    {
      title: "Web Development Essentials",
      subtitle: "- Free Code Camp",
      logo_path: "free-code-camp.svg",
      certificate_link:
        "https://www.freecodecamp.org/certification/Spidey_Acer/responsive-web-design",
      alt_name: "Free Code Camp",
      color_code: "#ffffff",
    },
    // {
    //   title: "Big Data",
    //   subtitle: "- Kim Akers",
    //   logo_path: "microsoft_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
    //   alt_name: "Microsoft",
    //   color_code: "#D83B0199",
    // },
    // {
    //   title: "Advanced Data Science",
    //   subtitle: "- Romeo Kienzler",
    //   logo_path: "ibm_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
    //   alt_name: "IBM",
    //   color_code: "#1F70C199",
    // },
    // {
    //   title: "Advanced ML on GCP",
    //   subtitle: "- GCP Training",
    //   logo_path: "google_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
    //   alt_name: "Google",
    //   color_code: "#0C9D5899",
    // },
    // {
    //   title: "DL on Tensorflow",
    //   subtitle: "- Laurence Moroney",
    //   logo_path: "deeplearning_ai_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
    //   alt_name: "deeplearning.ai",
    //   color_code: "#00000099",
    // },
    // {
    //   title: "Fullstack Development",
    //   subtitle: "- Jogesh Muppala",
    //   logo_path: "coursera_logo.png",
    //   certificate_link:
    //     "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
    //   alt_name: "Coursera",
    //   color_code: "#2A73CC",
    // },
    // {
    //   title: "Kuberenetes on GCP",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
    // {
    //   title: "Cryptography",
    //   subtitle: "- Saurabh Mukhopadhyay",
    //   logo_path: "nptel_logo.png",
    //   certificate_link:
    //     "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
    //   alt_name: "NPTEL",
    //   color_code: "#FFBB0099",
    // },
    // {
    //   title: "Cloud Architecture",
    //   subtitle: "- Qwiklabs",
    //   logo_path: "gcp_logo.png",
    //   certificate_link:
    //     "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
    //   alt_name: "GCP",
    //   color_code: "#4285F499",
    // },
  ],
};

const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with various companies and startups, gaining valuable experience in Software Development, Digital Platforms, and Business Development. I enjoy building innovative solutions and contributing to open-source projects.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Founder",
          company: "SpideyInc",
          company_url: "https://www.spideyinc.com",
          logo_path: "SpideyDpSVG.svg",
          duration: "Aug 2024 - Present",
          location: "Karen, Nairobi County, Kenya · Hybrid",
          description:
            "Leading an innovative tech company that combines AI technology with expert services. Developing and managing four key service areas: AI-powered smart tech shopping recommendations, custom software engineering solutions using cutting-edge technologies, creative graphic design services, and data-driven digital marketing strategies. Focused on delivering an unmatched tech shopping and service experience to clients.",
          color: "#9b1578",
        },
        {
          title: "Founder",
          company: "Cooky Chef",
          company_url: "https://www.cookychef.com/",
          logo_path: "cooky-chef.jpg",
          duration: "Jul 2024 - Present",
          location: "Dallas, Texas, United States · Hybrid",
          description:
            "Leading a startup venture focused on innovative culinary solutions, combining software development and digital marketing strategies.",
          color: "#000000",
        },
        {
          title: "Software Engineering Fellow",
          company: "Headstarter AI",
          company_url: "https://www.headstarter.com/en/",
          logo_path: "theheadstarter_logo.jpeg",
          duration: "Jul 2024 - Sep 2024",
          location: "New York, United States · Remote",
          description:
            "Led the development of 5 AI-powered applications in a team of 4 developers. Applied data structures and algorithms to optimize application performance. Utilized agile methodologies for project management and delivery. Collaborated with cross-functional teams to define and implement features.",
          color: "#000000",
        },
        {
          title: "Full Stack Developer & Freelance Graphic Designer",
          company: "Upwork",
          company_url: "https://www.upwork.com/",
          logo_path: "upwork_logo.jpeg",
          duration: "Mar 2022 - Apr 2024",
          location: "Remote",
          description:
            "Developed responsive web applications using React.js and Next.js, resulting in 30% improved user engagement. Implemented modern UI/UX designs using Tailwind CSS and GSAP animations. Collaborated with international clients to deliver high-quality solutions within project timelines. Maintained 95% client satisfaction rate through effective communication and timely delivery.",
          color: "#0879bf",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Commercial Intern - Diageo One",
          company: "Diageo",
          company_url: "https://www.diageo.com/",
          logo_path: "diageo.svg", // Add this logo to assets
          duration: "Aug 2024 - Nov 2024",
          location: "Nairobi County, Kenya · On-site",
          description:
            "Supported the Diageo One platform - A digital hub for trade partner interactions at East African Breweries Limited (EABL). Provided hands-on customer support, contributed to platform expansion, improved user experience based on feedback, and analyzed platform data for sales optimization. Directly involved in EABL's technological advancement to improve customer product access and purchasing experience.",
          color: "#000000",
        },
        {
          title: "Education Mentor",
          company: "Microverse",
          company_url: "https://www.microverse.org/",
          logo_path: "microverseinc_logo.jpeg",
          duration: "Jul 2023 - Dec 2023",
          location: "Remote",
          description:
            "Engaged in peer mentoring, code reviews, and virtual assistance while mastering full-stack development technologies including React.js, JavaScript, and databases. Focused on developing both technical skills and mentorship capabilities through peer-to-peer learning.",
          color: "#000000",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2022 - PRESENT",
          location: "Remote",
          description:
            "As a committed member of the GitHub Developer Program, I engage in substantial contributions to a wide array of open-source projects. My involvement spans across notable organizations such as TensorFlow, Uber, Facebook, Google, Scikit-learn, Kiwix, SymPy, Python, NVLabs, FOSSASIA, Netrack, and Keras. My contributions are multifaceted, encompassing bug resolutions, enhancement implementations, and the development of comprehensive documentation to bolster project accessibility and understanding.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects leverage a diverse array of cutting-edge technological tools. My expertise lies in developing responsive web applications and data science projects, and deploying them seamlessly to web platforms using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description: "Some of my published Articles, Blogs and Research.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "the-art-of-consistency",
      name: "The Art of Consistency",
      createdAt: "2024-07-15T00:00:00Z",
      description: "Crafting Masterpieces One Stroke at a Time",
      url:
        "https://medium.com/@peterjamesonsmontana/the-art-of-consistency-crafting-masterpieces-one-stroke-at-a-time-833f1dfc8ffe",
    },
    {
      id: "introduction-to-machine-learning",
      name: "Introduction to Machine Learning",
      createdAt: "2024-07-16T00:00:00Z",
      description: "An Essential Guide to Understanding Machine Learning",
      url:
        "https://medium.com/@peterjamesonsmontana/introduction-to-machine-learning-296cbbf40d1b",
    },
    {
      id: "consistency-models",
      name: "Consistency Models in AI",
      createdAt: "2024-07-12T00:00:00Z",
      description: "A Comprehensive Guide to Consistency Models in AI",
      url:
        "https://medium.com/@peterjamesonsmontana/understanding-consistency-models-in-ai-a-detailed-and-easy-to-comprehend-guide-330ce60fa187",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Let's Connect",
    profile_image_path: "portfolioDPGreen.png",
    description:
      "I'm readily accessible across various social media platforms. Should you wish to reach out, I guarantee a response within 24 hours. My expertise spans Machine Learning, Artificial Intelligence, React, Android Development, Cloud Computing, and Open Source Contributions.",
  },
  blogSection: {
    title: "Insights & Reflections",
    subtitle:
      "I regularly document insights from my professional journey and share knowledge on technical advancements and best practices.",
    link: "https://medium.com/@peterjamesonsmontana",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Kipevu Link, Karen, Nairobi, Kenya",
    locality: "Langata, Karen",
    country: "Kenya",
    region: "Africa",
    postalCode: "00100",
    streetAddress: "Hardy Karen",
    avatar_image_path: "SpideyQRWhatsapp.svg",
    location_map_link: "https://maps.app.goo.gl/ThScjE2R8apMzRpz8?g_st=ac",
  },
  phoneSection: {
    title: "Scan QR Code For Contact",
    subtitle: "Click Me! I'm a QR Code",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
